<template>
  <div>
    <div v-if="step == 2" class="pa-3 pb-0">
      <div id="agrupador-evento">
        <v-autocomplete
          v-model="eventoSelected"
          :items="listaCompletaEventos"
          :search-input.sync="searchEvento"
          color="primary"
          label="Evento"
          item-text="nome"
          item-value="id"
          placeholder="Encontre ou crie um agrupamento de evento"
          return-object
          small-chips
          class="text-truncate"
          :value="eventoSelected"
          @change="setChangeEvento"
        >
          <template v-slot:selection="data">
            <div>
              <v-chip>
                {{ data.item.nome }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-icon>
                <div>
                  <v-icon
                    color="rgba(50,50,50,0.2)"
                    v-text="'mdi-school'"
                  ></v-icon>
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.nome }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
          <template slot="no-data">
            <div v-if="addEvento == false" class="pa-2">
              Digite para adicionar ou encontrar seu grupo de evento
            </div>
            <div v-if="addEvento == true" class="pa-2">
              <small>
                Não encontramos o seu evento, deseja adicionar:
              </small>
              <br />
              <div class="pa-1">
                <strong>{{ searchEvento }}</strong>
              </div>
              <v-btn color="primary" class="pa-1 ma-1" @click="setNovoEvento()">
                confirmar evento
              </v-btn>
            </div>
          </template>
        </v-autocomplete>
      </div>

      <div>
        <v-autocomplete
          :items="listaCompletaInstituicoes"
          :search-input.sync="searchInstituicao"
          color="primary"
          item-text="nome"
          item-value="id"
          label="Instituição"
          placeholder="Encontre sua instituição"
          multiple
          return-object
          chips
          small-chips
          class="text-truncate"
          @change="setChangeInstituicao"
        >
          <template v-slot:selection="data">
            <div>
              <v-chip>
                {{ data.item.nome }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-icon>
                <div>
                  <v-icon
                    color="rgba(50,50,50,0.2)"
                    v-text="'mdi-domain'"
                  ></v-icon>
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.nome }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
          <template slot="no-data">
            <div v-if="addInst == false" class="pa-2">
              Digite para encontrar sua instituição
            </div>
            <div v-if="addInst == true" class="pa-2">
              <small>
                Não encontramos o sua instituição, deseja adicionar:
              </small>
              <br />
              <div class="pa-1">
                <strong>{{ searchInstituicao }}</strong>
              </div>
              <v-btn
                color="primary"
                class="pa-1 ma-1"
                @click="setNovaInstituicao()"
              >
                confirmar instituição
              </v-btn>
            </div>
          </template>
        </v-autocomplete>
      </div>
      <div>
        <v-autocomplete
          :items="listaCompletaCursos"
          :search-input.sync="searchCurso"
          color="primary"
          label="Cursos"
          item-text="nome"
          item-value="id"
          placeholder="Encontre seu curso"
          return-object
          small-chips
          multiple
          class="text-truncate"
          @change="setChangeCurso"
        >
          <template v-slot:selection="data">
            <div>
              <v-chip>
                {{ data.item.nome }}
              </v-chip>
            </div>
          </template>
          <template v-slot:item="data">
            <template>
              <v-list-item-icon>
                <div>
                  <v-icon
                    color="rgba(50,50,50,0.2)"
                    v-text="'mdi-school'"
                  ></v-icon>
                </div>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> {{ data.item.nome }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </template>
          <template slot="no-data">
            <div v-if="addCurso == false" class="pa-2">
              Digite para encontrar seu curso
            </div>
            <div v-if="addCurso == true" class="pa-2">
              <small>
                Não encontramos o seu curso, deseja adicionar:
              </small>
              <br />
              <div class="pa-1">
                <strong>{{ searchCurso }}</strong>
              </div>
              <v-btn color="primary" class="pa-1 ma-1" @click="setNovoCurso()">
                confirmar curso
              </v-btn>
            </div>
          </template>
        </v-autocomplete>
      </div>
      <div>
        <v-select
          v-model="selectedPeriodo"
          :items="listaDePeriodos"
          label="Período"
          item-text="nome"
          item-value="value"
        ></v-select>
      </div>
      <div>
        <v-text-field
          v-model.number="include.numeroFormando"
          label="Número aproximado de participantes"
          type="number"
        ></v-text-field>
      </div>
      <div class="pa-3 text-right">
        <v-btn color="secondaryDash" dark @click="validacoesEtapa1()">
          Próximo
        </v-btn>
      </div>
    </div>

    <div v-if="step == 3">
      <div>
        <v-text-field
          v-model="include.nome"
          label="Nome da turma"
        ></v-text-field>
      </div>
      <div>
        <v-text-field
          v-model="include.dataFormatura"
          v-mask="['##/##/####']"
          name="formaturaData"
          label="Data aproximada do evento *"
          hint="formato dd/mm/aaaa"
        ></v-text-field>
      </div>
      <div>
        <v-select
          v-model="include.estadoId"
          :items="listaEstados"
          label="Estado *"
          item-text="nome"
          item-value="id"
          @change="onChangeEstado"
        ></v-select>
      </div>
      <div>
        <v-select
          v-model="include.cidadeId"
          :items="listaCidades"
          label="Cidade * "
          item-text="descricao"
          item-value="id"
        ></v-select>
      </div>
      <div>
        <v-btn
          :loading="loading"
          x-large
          color="primary"
          @click="validacoesEtapa2()"
          >Cadastrar</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import copy from "copy-to-clipboard";
import moment from "moment";
import serviceInstituicao from "@/services/instituicao";
import serviceCursos from "@/services/cursos";
import serviceLocalidade from "@/services/localidade";
import serviceEventos from "@/services/eventos";
import serviceTurma from "@/services/turma";
import { URL_APP } from "@/constantes/base";
import EVENTOS from "@/constantes/EventosType";

export default {
  props: {
    step: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      eventoSelected: null,
      searchEvento: "",
      searchCurso: "",
      searchInstituicao: "",
      listaCompletaEventos: [],
      listaIncludesEventos: [],
      listaBuscaEventos: [],
      listaIncludesInstituicoes: [],
      listaCompletaInstituicoes: [],
      listaBuscaInstituicoes: [],
      listaBuscaCursos: [],
      listaIncludesCursos: [],
      listaCompletaCursos: [],
      listaEstados: [],
      listaCidades: [],
      timeSearch: 300,
      addEvento: false,
      loading: false,
      addCurso: false,
      addInst: false,
      listaDePeriodos: [
        { nome: "Integral", value: "DIURNO" },
        { nome: "Matutino", value: "MATUTINO" },
        { nome: "Vespertino", value: "VESPERTINO" },
        { nome: "Noturno", value: "NOTURNO" }
      ],
      selectedPeriodo: "MATUTINO",
      include: {
        nome: "",
        cidadeId: 0,
        estadoId: 0,
        cursos: [],
        instituicoes: [],
        dataFormatura: moment().format("DD/MM/YYYY"),
        tipoEvento: EVENTOS.FORMATURA,
        numeroFormando: 0,
        periodo: "MATUTINO"
      },
      resposta: ""
    };
  },
  computed: {
    getURL_APP() {
      return URL_APP;
    },
    getModal() {
      return this.$store.getters["aluno/getModalNovaTurma"];
    }
  },
  watch: {
    listaBuscaEventos() {
      let itensAtivos = this.listaIncludesEventos;
      let itensSearch = this.listaBuscaEventos;
      this.listaCompletaEventos = itensSearch.concat(itensAtivos);
    },
    listaBuscaInstituicoes() {
      let itensAtivos = this.listaIncludesInstituicoes;
      let itensSearch = this.listaBuscaInstituicoes;
      this.listaCompletaInstituicoes = itensSearch.concat(itensAtivos);
    },
    listaBuscaCursos() {
      let itensAtivos = this.listaIncludesCursos;
      let itensSearch = this.listaBuscaCursos;
      this.listaCompletaCursos = itensSearch.concat(itensAtivos);
    },
    selectedPeriodo(val) {
      let resp = val.toUpperCase();
      this.include.periodo = resp;
    },
    searchEvento() {
      this.debounceEvent();
    },
    searchCurso() {
      this.debounceCursos();
    },
    searchInstituicao() {
      this.debounceInstituicao();
    }
  },
  mounted() {
    this.$emit("setStep", 2);
    this.$emit("setMaxStep", 3);
    this.getEstados();
  },
  methods: {
    debounceEvent() {
      clearTimeout(this._timerId);
      let search = this.searchEvento;
      search = search ? search.trim() : null;
      this._timerId = setTimeout(() => {
        if (!search) {
          return false;
        }
        serviceEventos
          .searchEvent(search)
          .then(resp => {
            if (resp.status == 200) {
              this.listaBuscaEventos = resp.data;
              if (resp.data.length < 1 && search.length > 0) {
                this.addEvento = true;
              } else {
                this.addEvento = false;
              }
            }
          })
          .catch(err => {
            console.error(err);
          });
      }, this.timeSearch);
    },
    setNovoEvento() {
      let payload = {
        nome: this.searchEvento.trim(),
        id: 0
      };
      serviceEventos
        .createEvent(payload)
        .then(() => {
          this.debounceEvent();
        })
        .catch(err => {
          this.$root.showError(err.data);
        });
    },
    setChangeEvento(value) {
      if (value) {
        this.listaIncludesEventos = [{ ...value }];
        this.include.eventoId = value.id;
        this.include.eventoNome = value.nome;
      } else {
        this.include.eventoId = null;
        this.include.eventoNome = null;
      }
    },
    setChangeInstituicao(value) {
      this.listaIncludesInstituicoes = value;
      this.include.instituicoes = value;
    },
    setChangeCurso(value) {
      this.listaIncludesCursos = value;
      this.include.cursos = value;
    },
    validateData() {
      var dataValue = this.include.dataFormatura;
      if (!dataValue || dataValue.length != 10) {
        this.$root.showError(
          "O formato da data está inválido. modelo DD/MM/AAAA"
        );
        return false;
      }
      let dt = moment(dataValue, "DD/MM/YYYY");
      if (!dt.isValid()) {
        this.$root.showError("A data está inválida");
        return false;
      }
      if (!moment().isBefore(dt)) {
        this.$root.showError("A data deve ser maior que o dia atual");
        return false;
      }
      return true;
    },
    onChangeEstado() {
      serviceLocalidade.getCidadeForEstado(this.include.estadoId).then(resp => {
        this.listaCidades = resp.data;
      });
    },
    getEstados() {
      serviceLocalidade.getEstado().then(resp => {
        this.listaEstados = resp.data;
      });
    },
    copiar() {
      copy(`${URL_APP}/site/cadastro/${this.resposta.codigoConvite}`);
      this.$store.commit("global/setModal", {
        tipo: "informacao",
        mensagem: "Copiado com sucesso",
        toggle: true
      });
    },
    setNovaInstituicao() {
      let payload = {
        nome: this.searchInstituicao.trim(),
        sigla: "",
        id: 0
      };
      serviceInstituicao
        .includeInst(payload)
        .then(() => {
          this.debounceInstituicao();
        })
        .catch(err => {
          this.$root.showError(err.data);
          window.console.log(err);
        });
    },
    setNovoCurso() {
      let payload = {
        nome: this.searchCurso.trim(),
        id: 0
      };
      serviceCursos
        .includeCurso(payload)
        .then(() => {
          this.debounceCursos();
        })
        .catch(err => {
          this.$root.showError(err.data);
          window.console.log(err);
        });
    },
    cadastrarTurma() {
      this.loading = true;
      this.include.numeroFormando = Number(this.include.numeroFormando);
      let payload = this.include;
      payload.dataFormatura = moment(
        this.include.dataFormatura,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      serviceTurma
        .novoEvento(payload)
        .then(resp => {
          this.$emit("concluido", resp.data.codigoConvite);
          this.loading = false;
        })
        .catch(err => {
          this.$root.showError(err.data);
          this.loading = false;
        });
    },
    debounceCursos() {
      clearTimeout(this._timerId);
      let search = this.searchCurso;
      search = search ? search.trim() : null;
      this._timerId = setTimeout(() => {
        if (!search) {
          return false;
        }
        serviceCursos
          .buscar(search)
          .then(resp => {
            if (resp.status == 200) {
              this.listaBuscaCursos = resp.data;
              if (resp.data.length < 1 && search.length > 0) {
                this.addCurso = true;
              } else {
                this.addCurso = false;
              }
            }
          })
          .catch(err => {
            console.error(err);
          });
      }, this.timeSearch);
    },
    debounceInstituicao() {
      clearTimeout(this._timerId);
      let search = this.searchInstituicao;
      search = search ? search.trim() : null;
      this._timerId = setTimeout(() => {
        if (!search) {
          this.addInst = false;
          return false;
        }
        serviceInstituicao
          .buscar(search)
          .then(resp => {
            if (resp.status == 200) {
              this.listaBuscaInstituicoes = resp.data;
              if (resp.data.length < 1 && search.length > 1) {
                this.addInst = true;
              } else {
                this.addInst = false;
              }
            }
          })
          .catch(err => {
            console.error(err);
          });
      }, this.timeSearch);
    },
    validacoesEtapa1() {
      if (!this.include.instituicoes || this.include.instituicoes == "") {
        this.$root.showError("Instituição Inválida");
        return false;
      }
      if (!this.include.cursos || this.include.cursos == "") {
        this.$root.showError("Curso Inválido");
        return false;
      }
      if (!this.include.numeroFormando || this.include.numeroFormando <= 0) {
        this.$root.showError("Quantidade inválida");
        return false;
      }
      if (!this.include.periodo) {
        this.$root.showError("Periodo incorreto");
        return false;
      }
      let stepPayload = this.step + 1;
      this.$emit("setStep", stepPayload);
    },
    validacoesEtapa2() {
      let estadoID = this.include.estadoId;
      let cidadeID = this.include.cidadeId;
      if (!estadoID) {
        this.$root.showError("Estado não está selecionado");
        return false;
      }
      if (!cidadeID) {
        this.$root.showError("Cidade não está selecionada");
        return false;
      }
      if (!this.include.nome || this.include.length < 3) {
        this.$root.showError("Nome inválido, deve conter 3 caracteres");
        return false;
      }
      if (!this.include.dataFormatura) {
        this.$root.showError("Data da Formatura está incorreta");
        return false;
      }
      if (!this.validateData()) return false;
      this.cadastrarTurma();
    }
  }
};
</script>

<style lang="scss" scoped>
.mensagem {
  font-size: 16px;
  color: rgb(30, 30, 30);
}
.exposicao {
  padding: 20px 10px;
}
$btnAction: #111b47;
.btnFechar {
  color: white;
  background-color: $btnAction;
  width: 80%;
}
.request {
  padding: 10px;
  font-size: 20px;
  color: rgb(30, 30, 30);
  text-align: center;
  .msg {
    padding: 10px 0px;
    font-size: 16px;
  }
  button {
    padding: 10px 14px;
    display: inline-block;
    margin: 0 auto;
  }
}
.titulo {
  font-size: 25px;
  padding: 20px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  text-align: center;
}
</style>
