<template>
  <div>
    <vTitulo>
      Turmas
    </vTitulo>
    <div v-if="getUser.role == 'AGENCIA'" class="mx-4 mb-5">
      <v-btn depressed color="primary" @click="criarTurma()">
        Nova turma
      </v-btn>
    </div>
    <div class="mx-4 mb-2">
      <v-card class="lowShadow pa-4 white my-0 white align-center">
        <v-row justify="center" align="center">
          <v-col sm="10" cols="12">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="filtros.nome"
                  name="nomeTurma"
                  label="Nome do evento"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="filtros.codigo"
                  name="codigoTurma"
                  label="Código do evento"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="filtros.dataFormatura"
                  type="date"
                  name="dataFormatura"
                  label="Data do Evento"
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="2" class="text-center">
            <v-btn color="primary" large @click="consultaLista()">
              Buscar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <div class="ma-4">
      <v-data-table
        :headers="headers"
        :items="turmas"
        :options.sync="options"
        :server-items-length="totalTurmas"
        :loading="loading"
        class="lowShadow"
        loading-text="Carregando..."
        :footer-props="{
          itemsPerPageOptions: [20, 50, 100]
        }"
      >
        <template slot="headerCell" slot-scope="{ header }">
          <span
            class="subheading font-weight-light text--darken-3"
            v-text="header.text"
          />
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.name">
              <td class="textValueTable">EVT-{{ item.id }}</td>
              <td class="textValueTable">{{ item.nome }}</td>
              <td class="textValueTable text-center">{{ item.eventoNome }}</td>
              <td class="textValueTable">
                {{ item.cidade }} / {{ item.estado }}
              </td>
              <td class="textValueTable text-center">
                {{ item.numeroFormando }}
              </td>
              <td class="textValueTable">
                {{ item.pacoteTitulo }}
              </td>
              <td class="textValueTable">{{ item.dataFormatura }}</td>
              <td class="textValueTable">{{ item.dataCadastro | dataReal }}</td>
              <td class="textValueTable text-center">
                <div class="containButtons">
                  <v-btn
                    class="clique"
                    color="secondary"
                    icon
                    title="Informações detalhadas"
                    @click="openRouteDetail(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn
                    class="clique"
                    color="secondary"
                    icon
                    title="Informações detalhadas"
                    @click="openRouteUsers(item)"
                  >
                    <v-icon>mdi-account-group</v-icon>
                  </v-btn>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </div>
    <vDialogCadastrarTurma
      v-if="modalCadastro"
      @concluido="cadastroConcluido"
      @close="fecharCadastro"
      @update="consultaLista"
    />
  </div>
</template>

<script>
import serviceTurma from "@/services/turma";
import moment from "moment";
import { URL_APP } from "@/constantes/base";
import vDialogCadastrarTurma from "./cadastrarTurma/index.vue";

export default {
  components: {
    vDialogCadastrarTurma
  },
  filters: {
    /**
     * @param {moment.MomentInput} val
     */
    dataReal(val) {
      if (val) return moment(val).format("DD/MM/YYYY");
      else return "S/REGISTRO";
    }
  },
  data() {
    return {
      eventos: [],
      modalCadastro: false,
      payloadTurma: {},
      modalConfigModal: false,
      turmaDados: {},
      modalPlan: false,
      busca: "",
      page: 1,
      maxPage: 10,
      totalTurmas: 0,
      loading: true,
      filtros: {
        nome: "",
        dataFormatura: "",
        codigo: ""
      },
      options: {},
      turmas: [],
      headers: [
        { text: "ID", value: "id", align: "left" },
        { text: "Nome", value: "nome", align: "left" },
        { text: "Grupo Evento", value: "eventoNome", align: "left" },
        { text: "Cidade", value: "cidade", align: "left" },
        {
          text: "Qtd. Participantes",
          value: "numeroFormatura",
          align: "center"
        },
        { text: "Pacote", value: "pacoteTitulo" },
        { text: "Dt. Evento", value: "dataFormatura", align: "left" },
        { text: "Dt. Cadastro", value: "dataCadastro", align: "left" },
        { text: "Ações", align: "center" }
      ]
    };
  },
  computed: {
    getUser() {
      return this.$store.getters["gestao/getUser"];
    }
  },
  watch: {
    options: {
      handler(d) {
        this.page = d.page;
        this.maxPage = d.itemsPerPage;
        this.consultaLista();
      },
      deep: true
    }
  },
  mounted() {
    this.consultaLista();
  },
  methods: {
    /**
     * @param {{ id: Object; }} item
     */
    openRouteUsers(item) {
      if (!item.id) {
        this.$root.showError("Turma inválida");
        return false;
      }
      this.$store.commit("gestao/setDataTemp", item);
      this.$router.push(`participantes/${item.id}`);
    },
    updateLista() {},
    cadastroConcluido() {
      this.modalCadastro = false;
    },
    criarTurma() {
      this.modalCadastro = true;
    },
    fecharCadastro() {
      this.modalCadastro = false;
    },
    /**
     * @param {any} turma
     */
    openConfigModal(turma) {
      this.payloadTurma = turma;
      this.modalConfigModal = true;
    },
    closeConfig() {
      this.payloadTurma = {};
      this.modalConfigModal = false;
    },
    /**
     * @param {{ id: any; }} item
     */
    acessoAdmin(item) {
      const turmaID = item.id;
      const token = localStorage.getItem("APT-ADMTOKEN");
      window.open(
        `${URL_APP}/redirect-auth?token=${token}&turma=${turmaID}`,
        "_black"
      );
    },
    /**
     * @param {{ id: any; }} item
     */
    openRouteDetail(item) {
      this.$router.push(`detalhes/${item.id}`);
      this.turmaDados = item;
    },
    consultaLista() {
      this.loading = true;
      serviceTurma
        .getPaginationTurmas(this.page - 1, this.maxPage, this.filtros)
        .then(resp => {
          this.turmas = resp.data.content;
          this.totalTurmas = resp.data.totalElements;
        })
        .finally(() => {
          this.loading = false;
        })
        .catch(err => {
          this.$root.showError(err);
          console.log(err);
        });
    },
    /**
     * @param {{ id: any; disableNotification: any; }} item
     */
    disableNotification(item) {
      this.loading = true;
      serviceTurma
        .postDisableNotification(item.id, !item.disableNotification)
        .then(resp => {
          this.$root.showSucesso(resp.data);
          this.consultaLista();
        })
        .catch(err => {
          this.$root.showError(err);
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.textValueTable {
  font-size: 0.8rem !important;
  font-family: "Poppins" !important;
  font-weight: 400 !important;
  padding: 4px 8px !important;
  height: auto !important;
}
.containButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
}
</style>
