<template>
  <div>
    <v-dialog v-model="getDialogNovo" persistent max-width="700">
      <v-card class="mx-auto pa-5">
        <div class="d-grid grid-col-1-auto pa-1">
          <div class="text-left font-weight-bold primaryDash--text text-h5 ">
            Nova Turma
          </div>
          <div>
            <v-btn text @click="close">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <vStepCustom :count="step" :count-max="stepMax" />
        <div v-if="pagina == null" class="pa-2 text-center">
          <div class="d-inline-block mx-auto">
            <v-select
              v-model="selectedMount"
              :items="listaCategorias"
              item-text="name"
              item-value="code"
              label="Tipo de cadastro"
            ></v-select>
          </div>
          <div class="text-center pa-4">
            <v-btn color="primary" @click="avancar()">Próxima etapa</v-btn>
          </div>
        </div>
        <div class="contain">
          <cadastroFormatura
            v-if="pagina == getCode().FORMATURA"
            :step="step"
            @setStep="updateStep"
            @setMaxStep="updateMaxStep"
            @close="close"
            @concluido="openCongratulationsAndCopy"
          ></cadastroFormatura>
          <cadastroAssociacao
            v-if="pagina == getCode().ASSOCIACAO"
            :step="step"
            @setStep="updateStep"
            @setMaxStep="updateMaxStep"
            @close="close"
            @concluido="openCongratulationsAndCopy"
          />
          <cadastroCasamento
            v-if="pagina == getCode().CASAMENTO"
            :step="step"
            @setStep="updateStep"
            @setMaxStep="updateMaxStep"
            @close="close"
            @concluido="openCongratulationsAndCopy"
          />
          <cadastroOutro
            v-if="pagina == getCode().OUTRO"
            :step="step"
            @setStep="updateStep"
            @setMaxStep="updateMaxStep"
            @close="close"
            @concluido="openCongratulationsAndCopy"
          />
          <cadastroCorporativo
            v-if="pagina == getCode().CORPORATIVO"
            :step="step"
            @setStep="updateStep"
            @setMaxStep="updateMaxStep"
            @close="close"
            @concluido="openCongratulationsAndCopy"
          />
        </div>
      </v-card>
    </v-dialog>
    <vConcluido
      v-if="dialogConclusao"
      :code="code"
      @close="closeCongratulations"
    />
  </div>
</template>

<script>
import vStepCustom from "@/components/customStep.vue";
import codeID, { getArray } from "@/constantes/EventosType";
import vConcluido from "./concluido.vue";
import cadastroFormatura from "./formatura.vue";
import cadastroAssociacao from "./associacao.vue";
import cadastroCasamento from "./casamento.vue";
import cadastroOutro from "./outro.vue";
import cadastroCorporativo from "./corporativo.vue";

export default {
  components: {
    vStepCustom,
    vConcluido,
    cadastroFormatura,
    cadastroAssociacao,
    cadastroCasamento,
    cadastroOutro,
    cadastroCorporativo
  },
  data() {
    return {
      descricaoCustom: "",
      code: null,
      dialogConclusao: false,
      step: 1,
      stepMax: 3,
      pagina: null,
      selectedMount: "",
      listaCategorias: []
    };
  },
  computed: {
    getDialogNovo() {
      return true;
    }
  },
  beforeDestroy() {
    this.resetConfig();
  },
  beforeMount() {
    this.resetConfig();
  },
  mounted() {
    this.resetConfig();
  },
  methods: {
    resetConfig() {
      this.listaCategorias = getArray;
      this.step = 1;
      this.stepMax = 3;
      this.pagina = null;
      this.selectedMount = "";
      this.dialogConclusao = false;
      this.descricaoCustom = "";
      this.code = null;
    },
    openCongratulationsAndCopy(codigo) {
      this.resetConfig();
      this.step = 1;
      this.dialogConclusao = true;
      this.code = codigo;
    },
    closeCongratulations() {
      this.dialogConclusao = false;
      this.$emit("update");
      this.$emit("close");
    },
    getCode() {
      return codeID;
    },
    updateMaxStep(qtd) {
      this.stepMax = qtd;
    },
    updateStep(qtd) {
      this.step = qtd;
    },
    avancar() {
      this.pagina = this.selectedMount;
    },
    close() {
      this.step = 1;
      this.stepMax = 3;
      this.pagina = null;
      this.resetConfig();
      this.$emit("close");
    }
  }
};
</script>
